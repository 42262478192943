<template>
  <SectionCard :loading="loading">
    <div :class="$style.header">
      <VTabs
        v-model="tabData"
        @change="onTabChange"
      >
        <VTab v-if="canViewAccount" key="account" href="#account">Аккаунт организации</VTab>
        <VTab v-if="canViewApplications" key="account-applications" href="#account-applications">Заявки</VTab>
        <VTab key="account-list" href="#account-list" v-if="canViewAccountsList">Аккаунты</VTab>
      </VTabs>
      <div v-if="tabData === 'account-applications' && isInitiator">
        <VMenu bottom offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <VBtn color="primary" v-bind="attrs" v-on="on" depressed :disabled="isEmpty(types)">Создать заявку</VBtn>
          </template>
          <VList>
            <VListItem v-for="{ value, text } in types" :key="value" link @click="() => choseType(value)">
              <VListItemTitle>{{ text }}</VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>
      </div>
    </div>
    <VTabsItems v-model="tabData" class="mt-4">
      <VTabItem v-if="canViewAccount" key="account" value="account">
        <template v-if="!hasAccountApplication" v-show="!loading">
          <div class="mb-3">У вас ещё нет аккаунта организации</div>
          <VBtn color="primary" depressed @click="this.create">Заявка на создание аккаунта организации</VBtn>
        </template>
        <template v-if="hasAccountApplication" v-show="!loading">
          <template v-if="!hasAccount">
            <div class="mb-3">Вашу заявку ещё не одобрили</div>
            <VBtn color="primary" depressed @click="this.toActiveApplication">Перейти в активную заявку</VBtn>
          </template>
          <template v-else v-show="!loading">
            <AccountApplication :id="get(this.current, 'account.accountId')" isDetail/>
          </template>
        </template>
      </VTabItem>
      <VTabItem key="account-applications" value="account-applications" v-if="canViewApplications">
        <AccountApplicationList
          :headers="[
          { text: 'ID', value: 'id', sortable: true },
          { text: 'Дата заявки', value: 'dateCreate', sortable: true },
          { text: 'Организация', value: 'account.companyNameFull', alias: 'companyNameFull', sortable: false },
          { text: 'ИНН', value: 'account.inn', alias: 'inn', sortable: false },
          { text: 'КОД КСК', value: 'account.codeKsc', alias: 'codeKsc', sortable: false },
          { text: 'Тип организации', value: 'account.type', alias: 'organizationType', options: values(organizationTypes), sortable: false },
          { text: 'Статус', value: 'status', options: values(statuses), sortable: false },
          { text: 'Тип заявки', value: 'type', options: values(createTypes), sortable: false },
        ]"
          :items="list.items"
          :count="list.navCount"
          :page="page"
          :size="size"
          :loading="loading"
        />
      </VTabItem>
      <VTabItem key="account-list" value="account-list" v-if="canViewAccountsList">
        <AccountList
          :headers="[
          { text: 'ID', value: 'id', sortable: true },
          { text: 'Дата заявки', value: 'dateCreate', sortable: true },
          { text: 'Организация', value: 'companyNameFull', alias: 'companyNameFull', sortable: false },
          { text: 'ИНН', value: 'inn', alias: 'inn', sortable: false },
          { text: 'КОД КСК', value: 'codeKsc', alias: 'codeKsc', sortable: false },
          { text: 'Тип организации', value: 'type', alias: 'organizationType', options: values(organizationTypes), sortable: false },
          { text: 'Состояние', value: 'isActive', alias: 'isActive', options: [{ text: 'Активен', value: 'Y' }, { text: 'Не активен', value: 'N' }], sortable: false,  multiple: false },
          { text: 'Подтверждён', value: 'isVerified', alias: 'isVerified', options: [{ text: 'Подтверждён', value: 'Y' }, { text: 'Не подтверждён', value: 'N' }], sortable: false, multiple: false },
        ]"
          :items="accountList.items"
          :count="accountList.navCount"
          :page="page"
          :size="size"
          :loading="loading"
        />
      </VTabItem>
    </VTabsItems>
  </SectionCard>
</template>

<script>
import {debounce, get, isEmpty, isNull, omitBy, toString, values} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import SectionCard from '@/components/user/SectionCard/SectionCard';
import {
  ACCOUNT_APPLICATION_CREATE_TYPES,
  ACCOUNT_APPLICATION_STATUSES_INITIATOR,
  ACCOUNT_APPLICATION_STATUSES_ADMINISTRATOR,
  ACCOUNT_APPLICATION_TYPES, ACCOUNT_APPLICATION_ORGANIZATION_TYPES
} from '@/store/account-application/enums';
import AccountApplicationList from '@/components/account-application/components/AccountApplicationList.vue';
import AccountApplication from '@/components/account-application/AccountApplication.vue';
import AccountList from '@/components/account/Account/components/AccountList.vue';

export default {
  name: 'Account',
  components: {
    AccountList,
    AccountApplication,
    AccountApplicationList,
    SectionCard,
  },
  props: {
    tab: { type: String, default: 'account' },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  data() {
    return {
      tabData: null,
      filters: {
        'account-list': {},
        'account-applications': {},
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: 'accountApplication/pending',
      list: 'accountApplication/list',
      accountList: 'account/list',
      hasRole: 'user/hasRole',
      accountStatus: 'user/accountStatus',
      current: 'user/current',
    }),
    types() {
      return get(this.current, 'account.availableTypes', [])
    },
    statuses() {
      return this.isAdministrator ? ACCOUNT_APPLICATION_STATUSES_ADMINISTRATOR : ACCOUNT_APPLICATION_STATUSES_INITIATOR;
    },
    createTypes() {
      return ACCOUNT_APPLICATION_CREATE_TYPES;
    },
    organizationTypes() {
      return ACCOUNT_APPLICATION_ORGANIZATION_TYPES;
    },
    hasAccountApplication() {
      return !!get(this.current, 'account.id');
    },
    hasAccount() {
      return !!get(this.current, 'account.accountId');
    },
    isAdministrator() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ADMINISTRATOR.value'), 'account');
    },
    isInitiator() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'INITIATOR.value'), 'account');
    },
    isAccountAuthority() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_AUTHORITY.value'), 'account');
    },
    isAccountSlave() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_SLAVE.value'), 'account');
    },
    isAccountObserver() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_OBSERVER.value'), 'account');
    },
    canViewAccount() {
      return this.isInitiator || this.isAccountAuthority || this.isAccountSlave;
    },
    canViewAccountsList() {
      return this.isAdministrator || this.isAccountObserver;
    },
    canViewApplications() {
      return !this.isAccountAuthority && !this.isAccountSlave;
    }
  },
  methods: {
    values,
    get,
    isEmpty,
    ...mapActions({
      fetchCurrent: 'user/fetchCurrent',
      fetchAccountApplicationList: 'accountApplication/getAccountApplicationList',
      fetchAccountList: 'account/getAccountList',
      createApplicationAccount: 'accountApplication/createApplicationAccount',
    }),
    onUpdateState: debounce(function () {
      this.update();
    }, 500),
    update() {
      const { page, size, filter } = this;
      this.filters[this.tabData] = { page, size, ...filter };
      if (this.tabData === 'account-applications') {
        return this.fetchAccountApplicationList({ page, size, filter });
      } else if (this.tabData === 'account-list') {
        return this.fetchAccountList({ page, size, filter: { ...filter } });
      }
    },
    onTabChange(tab) {
      const { query } = this.$route;
      if (toString(get(query, 'tab', '')) !== tab) {
        this.$router.push({ query: omitBy({ ...this.filters[tab], tab }, isNull) });
      }
    },
    async create() {
      const detail = await this.createApplicationAccount({ type: get(ACCOUNT_APPLICATION_CREATE_TYPES, 'CREATE.value') })
      this.$notify({
        type: 'success',
        title: 'Успех',
        text: 'Заявка успешно создана',
      });
      this.$router.push({ name: 'account/accountApplicationView', params: {application: get(detail, 'id')} });
    },
    toActiveApplication(){
      this.$router.push({ name: 'account/accountApplicationView', params: {application: get(this.current, 'account.id')} });
    },
    async choseType(code) {
      const detail = await this.createApplicationAccount({ type: get(ACCOUNT_APPLICATION_CREATE_TYPES, [code, 'value']) })
      this.$router.push({ name: 'account/accountApplicationView', params: {application: get(detail, 'id')} });
    },
    // Пока отказались от этого
    async onCreateAccount() {
      const detail = await this.createApplicationAccount({ type: get(ACCOUNT_APPLICATION_CREATE_TYPES, 'MANUAL.value') })
      this.$notify({
        type: 'success',
        title: 'Успех',
        text: 'Аккаунт успешно создан',
      });
      this.$router.push({ name: 'account/accountUserCreateView', params: {user: get(detail, 'id')} });
    }
  },
  watch: {
    page: function () {
      this.onUpdateState();
    },
    size: function () {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function () {
        this.onUpdateState();
      }
    }
  },
  beforeMount() {
    this.tabData = this.tab;
    this.onTabChange(this.tabData);
    this.update();
    this.fetchCurrent()
  }
}
</script>

<style module lang="scss">
.header {
  position: relative;
  display: flex;
  gap: 16px;
  justify-content: space-between;
}
</style>
